// @flow

import React from 'react'
import {
    useBlock,
    ImageBlock,
} from '@shift-marketing/shift-one-website-editor'

import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import schema from './properties.schema.json'

type Props = {}

const FLEX_MAP = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
}

function TopBar (props: Props) {
    const { properties, ref } = useBlock('topBar', schema)

    return (
        <div
            ref={ref}
            className={styles.topBar}
            style={{
                color: props.theme.color,
                backgroundColor: properties.backgroundColor,
                paddingTop: `${properties.verticalPadding / 16}em`,
                paddingBottom: `${properties.verticalPadding / 16}em`
            }}
        >
            <div className={styles.container} style={{
              justifyContent: `${FLEX_MAP[properties.logoAlignment]}`
            }}>
                <ImageBlock
                    blockId="header.logo"
                    defaultImage="$.logo.inverted"
                    resize='both'
                    maxWidth='196px'
                    title='Header logo'
                />
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(TopBar)
