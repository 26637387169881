export default {
    typeIdentifier: 'alumni_gift_2021_2',
    lead: {
        fields: {
            firstName: 'John',
            lastName: 'Shifty',
            province: 'FL',
            postalCode: '33160',
            email: 'john.shifty@shiftone.io',
            dayPhone: '(555) 123 5678',
            classYear: 2005,
            addressLine1: '1 Graduate Street',
            city: 'Alma Mater',
            homePhone: '(123) 123-1231'
        }
    },
    campaign: {
        videos: {
            masthead: 'https://vimeo.com/268407434'
        },
        website: {},
        websiteConfig: {
            blocks: {
                landing: {
                    header: {
                        video: {
                            button: {
                                videoUrl: 'https://vimeo.com/268407434'
                            }
                        },
                        video1: {
                            button: {
                                videoUrl: 'https://vimeo.com/268407434'
                            }
                        }
                    }
                }
            }
        }
    }
}
